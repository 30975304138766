<template>
	
		<header class="wrapper bg-light">
			<div class="bg-primary text-white fw-bold fs-15 mb-2">
				<div class="container py-2 d-md-flex flex-md-row">
					<div class="d-flex flex-row align-items-center">
						<div class="icon text-white fs-22 mt-1 me-2"> <i class="uil uil-location-pin-alt"></i></div>
						<address class="mb-0">Jalan Poros Andoolo</address>
					</div>
					<div class="d-flex flex-row align-items-center me-6 ms-auto">
						<div class="icon text-white fs-22 mt-1 me-2"> <i class="uil uil-phone-volume"></i></div>
						<p class="mb-0">+62 822-9689-5907</p>
					</div>
					<div class="d-flex flex-row align-items-center">
						<div class="icon text-white fs-22 mt-1 me-2"> <i class="uil uil-message"></i></div>
						<p class="mb-0"><a href="mailto:sandbox@email.com" class="link-white hover">dpmptsp@konaweselatankab.go.id</a></p>
					</div>
				</div>
				<!-- /.container -->
			</div>
			<nav class="navbar navbar-expand-lg center-nav transparent navbar-light">
				<div class="container flex-lg-row flex-nowrap align-items-center">
				<div class="navbar-brand w-100">
					<!-- <a href="./index.html">
						<img src="/assets/img/logo.png" srcset="/assets/img/logo@2x.png 2x" alt="" />
					</a> -->
				</div>
				<div :class="{'show': side, '': !side}" class="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
					<div class="offcanvas-header d-lg-none">
						<h3 class="text-white fs-30 mb-0">Sandbox</h3>
						<button @click="side()" type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
					</div>
					<div class="offcanvas-body ms-lg-auto d-flex flex-column h-100">
					<ul class="navbar-nav">
						<li class="nav-item"><router-link class="nav-link" to="/">Beranda</router-link></li>
						<li class="nav-item"><router-link class="nav-link" to="/berita">Berita</router-link></li>
						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Profil</a>
							<ul class="dropdown-menu">
								<!-- <li class="nav-item"><router-link class="dropdown-item" to="/">Daftar Riwayat Pimpinan</router-link></li> -->
								<li class="nav-item"><router-link class="dropdown-item" to="/dataPegawai">Data Pegawai</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/dataOutsourcing">Data Pegawai Outsourcing</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/strukturOrganisasi">Struktur Organisasi</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/tugasPokok">Tugas Pokok</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/visiMisi">Visi & Misi</router-link></li>
							</ul>
						</li>

						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Unit Kerja</a>
							<ul class="dropdown-menu">
								<li class="nav-item"><router-link class="dropdown-item" to="/bidangPromosi">Bidang Promosi</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/bidangPenanamanModal">Bidang Penanaman Modal</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/bidangInformasiPengaduan">Bidang Informasi & Pengaduan</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/bidangPerizinan">Bidang Perizinan & Non Perizinan</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/sekretariat">Sekretariat</router-link></li>
							</ul>
						</li>

						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Pelayanan</a>
							<ul class="dropdown-menu">
								<li class="nav-item"><router-link class="dropdown-item" to="/alurPelayanan">Alur Pelayanan</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/jenisLayanan">Jenis Layanan dan Persyaratan</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/standarWaktu">Standar Waktu Pelayanan dan Biaya</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/formulirPerizinan">Formulir dan Informasi Layanan Perizinan</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/sopPelayanan">SOP Pelayanan</router-link></li>
							</ul>
						</li>

						<li class="nav-item dropdown dropdown-mega">
							<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Dokumen</a>
							<ul class="dropdown-menu mega-menu">
								<li class="mega-menu-content">
								<div class="row gx-0 gx-lg-3">
									<div class="col-lg-4">
										<h6 class="dropdown-header">Laporan & Dokumen</h6>
										<ul class="list-unstyled">
											<li class="nav-item"><router-link class="dropdown-item" to="/forumKonsultasiPublik">Forum Konsultasi Publik</router-link></li>
											<li class="nav-item"><router-link class="dropdown-item" to="/rewardPunishmentPegawai">Reward dan Punishment Pegawai</router-link></li>
											<li class="nav-item"><router-link class="dropdown-item" to="/renstra">Rencana Strategis</router-link></li>
											<li class="nav-item"><router-link class="dropdown-item" to="/rekapIzin">Rekapitulasi Izin</router-link></li>
											<li class="nav-item"><router-link class="dropdown-item" to="/rekapPad">Rekapitulasi PAD</router-link></li>
											<li class="nav-item"><router-link class="dropdown-item" to="/penghargaan">Penghargaan</router-link></li>
											<li class="nav-item"><router-link class="dropdown-item" to="/laporanIKM">Laporan IKM</router-link></li>
											<!-- <li class="nav-item"><router-link class="dropdown-item" to="/">Dokumen Lainnya</router-link></li> -->
										</ul>
									</div>
									<div class="col-lg-4">
										<h6 class="dropdown-header">Realisasi Investasi</h6>
										<ul class="list-unstyled">
											<li class="nav-item"><router-link class="dropdown-item" to="/realisasiInvestasi">Realisasi Investasi</router-link></li>
										</ul>
									</div>
									<!-- <div class="col-lg-3">
										<h6 class="dropdown-header">Izin Terbit</h6>
										<ul class="list-unstyled">
											<li class="nav-item"><router-link class="dropdown-item" to="/">Izin Terbit</router-link></li>
										</ul>
									</div> -->
									<div class="col-lg-4">
										<h6 class="dropdown-header">OSS RBA</h6>
										<ul class="list-unstyled">
											<li class="nav-item"><router-link class="dropdown-item" to="/panduan">Panduan OSS</router-link></li>
											<li class="nav-item"><router-link class="dropdown-item" to="/produkHukum">Produk Hukum</router-link></li>
										</ul>
									</div>
								</div>
								<!--/.row -->
								</li>
								<!--/.mega-menu-content-->
							</ul>
							<!--/.dropdown-menu -->
						</li>

						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Galeri</a>
							<ul class="dropdown-menu">
								<li class="nav-item"><router-link class="dropdown-item" to="/foto">Foto</router-link></li>
								<li class="nav-item"><router-link class="dropdown-item" to="/">Video</router-link></li>
							</ul>
						</li>

						<!-- <li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Interaksi Masyarakat</a>
							<ul class="dropdown-menu">

								<li class="nav-item"><router-link class="dropdown-item" to="/">Indeks Kepuasan Masyarakat</router-link></li>
								<li class="dropdown dropdown-submenu dropend"><a class="dropdown-item dropdown-toggle" href="#" data-bs-toggle="dropdown">Hubungi Kami</a>
									<ul class="dropdown-menu">
										<li class="nav-item"><router-link class="dropdown-item" to="/">Pengaduan</router-link></li>
										<li class="nav-item"><router-link class="dropdown-item" to="/">Informasi</router-link></li>
										<li class="nav-item"><router-link class="dropdown-item" to="/">Agresiasi</router-link></li>
									</ul>
								</li>
							</ul>
						</li> -->

						<!-- <li class="nav-item"><router-link class="nav-link" to="/">Peta Investasi</router-link></li> -->
						 <li class="nav-item">
							<a class="nav-link" href="https://peindio-gis.konaweselatankab.go.id/" target="_blank">
								PEINDIO
							</a>
						</li>
						<!-- https://perak.konaweselatankab.go.id/ -->
					</ul>
					<!-- /.navbar-nav -->
					<div class="offcanvas-footer d-lg-none">
						<div>
						<a href="mailto:first.last@email.com" class="link-inverse">info@email.com</a>
						<br /> 00 (123) 456 78 90 <br />
						<nav class="nav social social-white mt-4">
							<a href="#"><i class="uil uil-twitter"></i></a>
							<a href="#"><i class="uil uil-facebook-f"></i></a>
							<a href="#"><i class="uil uil-dribbble"></i></a>
							<a href="#"><i class="uil uil-instagram"></i></a>
							<a href="#"><i class="uil uil-youtube"></i></a>
						</nav>
						<!-- /.social -->
						</div>
					</div>
					<!-- /.offcanvas-footer -->
					</div>
					<!-- /.offcanvas-body -->
				</div>
				<!-- /.navbar-collapse -->
				<div class="navbar-other w-100 d-flex ms-auto">
					<ul class="navbar-nav flex-row align-items-center ms-auto">
					<li class="nav-item dropdown language-select text-uppercase">
						<!-- <a class="nav-link dropdown-item dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">En</a>
						<ul class="dropdown-menu">
						<li class="nav-item"><a class="dropdown-item" href="#">En</a></li>
						<li class="nav-item"><a class="dropdown-item" href="#">De</a></li>
						<li class="nav-item"><a class="dropdown-item" href="#">Es</a></li>
						</ul> -->
					</li>
					<li class="nav-item d-none d-md-block">
						<!-- <a href="./contact.html" class="btn btn-sm btn-primary rounded-pill">Contact</a> -->
					</li>
					<li class="nav-item d-lg-none">
						<button @click="side()" class="hamburger offcanvas-nav-btn"><span></span></button>
					</li>
					</ul>
					<!-- /.navbar-nav -->
				</div>
				<!-- /.navbar-other -->
				</div>
				<!-- /.container -->
			</nav>
		<!-- /.navbar -->
		</header>
	
</template>

<script>
export default {
	data(){
		return{
			side:false
		}
	},
	methods: {
		side : function(){
			this.side = !this.side
			// this.isActive = !this.isActive; 
		},
	}

}
</script>

<style></style>