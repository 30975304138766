import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/berita',
    name: 'berita',
    component: () => import('../views/berita/berita.vue'),
  },
  {
    path: '/isiBerita',
    name: 'isiBerita',
    component: () => import('../views/berita/isiBerita.vue'),
  },

  {
    path: '/dataPegawai',
    name: 'dataPegawai',
    component: () => import('../views/profil/dataPegawai.vue'),
  },

  {
    path: '/dataOutsourcing',
    name: 'dataOutsourcing',
    component: () => import('../views/profil/dataOutsourcing.vue'),
  },

  {
    path: '/strukturOrganisasi',
    name: 'strukturOrganisasi',
    component: () => import('../views/profil/strukturOrganisasi.vue'),
  },

  {
    path: '/tugasPokok',
    name: 'tugasPokok',
    component: () => import('../views/profil/tugasPokok.vue'),
  },

  {
    path: '/visiMisi',
    name: 'visiMisi',
    component: () => import('../views/profil/visiMisi.vue'),
  },

  {
    path: '/bidangPromosi',
    name: 'bidangPromosi',
    component: () => import('../views/unitKerja/bidangPromosi.vue'),
  },

  {
    path: '/bidangPenanamanModal',
    name: 'bidangPenanamanModal',
    component: () => import('../views/unitKerja/bidangPenanamanModal.vue'),
  },

  {
    path: '/bidangInformasiPengaduan',
    name: 'bidangInformasiPengaduan',
    component: () => import('../views/unitKerja/bidangInformasiPengaduan.vue'),
  },

  {
    path: '/bidangPerizinan',
    name: 'bidangPerizinan',
    component: () => import('../views/unitKerja/bidangPerizinan.vue'),
  },

  {
    path: '/sekretariat',
    name: 'sekretariat',
    component: () => import('../views/unitKerja/sekretariat.vue'),
  },

  {
    path: '/alurPelayanan',
    name: 'alurPelayanan',
    component: () => import('../views/layanan/alurPelayanan.vue'),
  },

  {
    path: '/jenisLayanan',
    name: 'jenisLayanan',
    component: () => import('../views/layanan/jenisLayanan.vue'),
  },

  {
    path: '/standarWaktu',
    name: 'standarWaktu',
    component: () => import('../views/layanan/standarWaktu.vue'),
  },

  {
    path: '/formulirPerizinan',
    name: 'formulirPerizinan',
    component: () => import('../views/layanan/formulirPerizinan.vue'),
  },

  {
    path: '/sopPelayanan',
    name: 'sopPelayanan',
    component: () => import('../views/layanan/sopPelayanan.vue'),
  },

  {
    path: '/sopPelayanan',
    name: 'sopPelayanan',
    component: () => import('../views/layanan/sopPelayanan.vue'),
  },

  {
    path: '/forumKonsultasiPublik',
    name: 'forumKonsultasiPublik',
    component: () => import('../views/laporanDokumen/forumKonsultasiPublik.vue'),
  },

  {
    path: '/rewardPunishmentPegawai',
    name: 'rewardPunishmentPegawai',
    component: () => import('../views/laporanDokumen/rewardPunishmentPegawai.vue'),
  },

  {
    path: '/laporanIKM',
    name: 'laporanIKM',
    component: () => import('../views/laporanDokumen/laporanIKM.vue'),
  },

  {
    path: '/penghargaan',
    name: 'penghargaan',
    component: () => import('../views/laporanDokumen/penghargaan.vue'),
  },

  {
    path: '/rekapIzin',
    name: 'rekapIzin',
    component: () => import('../views/laporanDokumen/rekapIzin.vue'),
  },

  {
    path: '/rekapPad',
    name: 'rekapPad',
    component: () => import('../views/laporanDokumen/rekapPad.vue'),
  },

  {
    path: '/renstra',
    name: 'renstra',
    component: () => import('../views/laporanDokumen/renstra.vue'),
  },

  {
    path: '/realisasiInvestasi',
    name: 'realisasiInvestasi',
    component: () => import('../views/realisasiInvestasi/realisasiInvestasi.vue'),
  },

  {
    path: '/panduan',
    name: 'panduan',
    component: () => import('../views/oss_rba/panduan.vue'),
  },

  {
    path: '/produkHukum',
    name: 'produkHukum',
    component: () => import('../views/oss_rba/produkHukum.vue'),
  },

  {
    path: '/foto',
    name: 'foto',
    component: () => import('../views/galeri/foto.vue'),
  },

  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
