<template>
	<div>
		<footer class="bg-dark text-inverse">
			<div class="container py-13 py-md-15">
				<div class="row gy-6 gy-lg-0">
					<div class="col-md-4 col-lg-4">
						<div class="widget">
							<p class="mb-4">© 2024 DPMPTSP KAB. KONSEL. <br class="d-none d-lg-block" />All rights reserved.</p>
							<nav class="nav social social-white">
								<a href="#"><i class="uil uil-twitter"></i></a>
								<a href="#"><i class="uil uil-facebook-f"></i></a>
								<a href="#"><i class="uil uil-dribbble"></i></a>
								<a href="#"><i class="uil uil-instagram"></i></a>
								<a href="#"><i class="uil uil-youtube"></i></a>
							</nav>
						</div>
					</div>
					<div class="col-md-4 col-lg-4">
						<div class="widget">
							<h4 class="widget-title text-white mb-3">Get in Touch</h4>
							<address class="pe-xl-15 pe-xxl-17">
								Potoro, Kec. Andoolo, Kabupaten Konawe Selatan, Sulawesi Tenggara 93819
							</address>
							<a href="#">dpmptsp@konaweselatankab.go.id</a><br /> +62 822-9689-5907
						</div>
					</div>
					<div class="col-md-4 col-lg-4">
						<div class="widget">
							<h4 class="widget-title text-white mb-3">Informasi</h4>
							<ul class="list-unstyled  mb-0">
								<li><router-link to="/berita">Berita</router-link></li>
								<li><router-link to="/dataPegawai">Data Pegawai</router-link></li>
								<li><router-link to="/standarWaktu">Standar Waktu Pelayanan</router-link></li>
								<li><router-link to="/standarWaktu">Formulir dan Informasi Layanan</router-link></li>
								<li><a class="nav-link" href="https://peta-investasi.konaweselatankab.go.id/" target="_blank">Peta Investasi</a></li>
							</ul>
						</div>
					</div>
				</div>
				<!--/.row -->
			</div>
			<!-- /.container -->
		</footer>
	</div>
</template>

<script>
export default {

}
</script>

<style></style>