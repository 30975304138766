<template>
    <div>
        <section class="wrapper bg-light">
            <div id="carouselExampleAutoplaying" class="carousel slide mb-100" data-bs-ride="carousel" style="background-color: aqua;">
                <div class="carousel-inner">
                    <!-- <div v-for="(data, idx) in list_slider" :key="data.id" :class="cek_active(idx) +' item'"  ></div> -->
                    <div v-for="(data, idx) in list_slider" :key="data.id" :class="'carousel-item '+cek_active(idx)">
                        <img :src="file_path+data.file" alt="Banner Image Here" class="img-responsive full-sc">
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </section> <br><br>

        <!-- <section class="wrapper bg-light">
            <div class="container py-14 py-md-16">
                <div class="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                    <div class="col-md-8 col-lg-6 col-xl-5 order-lg-2 position-relative">
                        <div class="shape bg-soft-primary rounded-circle rellax w-20 h-20" data-rellax-speed="1" style="top: -2rem; right: -1.9rem;"></div>
                        <figure class="rounded"><img src="assets/img/photos/about7.jpg" srcset="assets/img/photos/about7@2x.jpg 2x" alt=""></figure>
                    </div>
                    <div class="col-lg-6">
                        <h2 class="display-4 mb-3">Sambutan Kepala Dinas</h2>
                        <p class="mb-6" v-html="sambutan_isi"></p>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="wrapper bg-light wrapper-border mt-100">
            <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                <h3 class="display-4 mb-10">Berita Terbaru</h3>
            </div>
            <div class="container">
                <div class="blog grid grid-view">
                    <div class="row isotope gx-md-8 gy-8 mb-8">
                        <article class="item post col-md-4" v-for="data in list_berita" :key="data.id">
                        <div class="card">
                            <figure class="card-img-top overlay overlay-1 hover-scale">
                                <a @click="push_berita(data.id)" href="javascript:void(0)">
                                    <img :src="file_path+data.foto" alt="" style="width: 100%; height: 300px !important;"/>
                                </a>
                            <figcaption>
                                <h5 class="from-top mb-0">Selengkapnya</h5>
                            </figcaption>
                            </figure>
                            <div class="card-body">
                            <div class="post-header">
                                <div class="post-category text-line">
                                    <a href="#" class="hover" rel="category">{{ data.kategori_id }}</a>
                                </div>
                                <h2 class="post-title h3 mt-1 mb-3">
                                    <a @click="push_berita(data.id)" class="link-dark" href="javascript:void(0)">
                                        {{ data.judul }}
                                    </a>
                                </h2>
                            </div>
                            <div class="post-content">
                                <p>{{ data.deskripsi }}</p>
                            </div>
                            </div>
                            <div class="card-footer">
                                <ul class="post-meta d-flex mb-0">
                                    <li class="post-date"><i class="uil uil-calendar-alt"></i><span>{{ convert_tgl(data.createAt) }}</span></li>
                                </ul>
                            </div>
                        </div>
                        </article>
                    </div>
                    <div class="text-center">
                        <router-link  to="/berita" class="btn btn-blue rounded-pill mb-50">BERITA LAINNYA</router-link> <br> <br>
                    </div>
                </div>
            </div>
        </section>

        <section class="wrapper bg-light wrapper-border mt-100">
            <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                <h3 class="display-4 mb-10">Foto Kegiatan Terbaru</h3>
            </div>
            <div class="container">
                <div class="blog grid grid-view">
                    <div class="row isotope gx-md-8 gy-8 mb-8">
                        <article class="item post col-md-4" v-for="data in list_foto" :key="data.id">
                        <div class="card">
                            <figure class="card-img-top overlay overlay-1 hover-scale">
                                <a @click="selectDataFoto(data)" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#modal-signin">
                                    <img :src="file_path+data.file" alt="" style="width: 100%; height: 300px !important;"/>
                                </a>
                            </figure>
                            <div class="card-body">
                                <div class="post-header">
                                    <h2 class="post-title h3 mt-1 mb-3">
                                        <a class="link-dark" @click="selectDataFoto(data)" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#modal-signin">
                                            {{ data.judul }}
                                        </a>
                                    </h2>
                                </div>
                            </div>
                            <div class="modal fade" id="modal-signin" tabindex="-1">
                                <div class="modal-dialog modal-lg modal-dialog-centered modal-sm">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            <!-- <p v-html="form.uraian"></p> -->
                                            <img :src="file_path+galeryku.file" alt="" style="width: 100%; height: auto !important;"/>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Keluar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </article>
                    </div>
                    <div class="text-center">
                        <router-link  to="/foto" class="btn btn-blue rounded-pill mb-50">FOTO LAINNYA</router-link> <br> <br>
                    </div>
                </div>
            </div>
        </section>

        <section class="wrapper bg-light wrapper-border">
            <div class="container pt-15 pt-md-17 pb-13 pb-md-15">
                <h2 class="fs-15 text-uppercase text-muted mb-3">Link Terkait</h2>

                <!-- <div class="row gx-lg-8 mb-10 gy-5">
                    <div class="col-lg-6">
                        <h3 class="display-5 mb-0">We are trusted by over 300+ clients. Join them by using our services and grow your business.</h3>
                    </div>
                    <div class="col-lg-6">
                        <p class="lead mb-0">Donec ullamcorper nulla non metus auctor fringilla. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. Maecenas faucibus mollis interdum. Cras justo odio mollis.</p>
                    </div>
                </div> -->

                <div class="row row-cols-2 row-cols-md-3 row-cols-xl-5 gx-lg-6 gy-6 justify-content-center">
                    <div class="col" v-for="data in list_link_terkait" :key="data.id">
                        <div class="card shadow-lg h-100 align-items-center">
                            <div class="card-body align-items-center d-flex px-3 py-6 p-md-8">
                                <figure class="px-md-3 px-xl-0 px-xxl-3 mb-0">
                                    <a :href="data.url" target="_blank">
                                        <img :src="file_path+data.file" alt="" />
                                    </a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

    const URL_SLIDER = URL_APP + "api/v1/publish_dpmptsp_slider/";
    const TENTANG_URL = URL_APP + "api/v1/publish_dpmptsp_profil/";
    const SAMBUTAN_URL = URL_APP + "api/v1/publish_dpmptsp_sambutan/";
    const ADD_URL_BERITA = URL_APP + "api/v1/web_publish_berita/";
    const ADD_URL_FOTO = URL_APP + "api/v1/publish_dpmptsp_foto/";
    const FILE_LOAD = URL_APP + "uploads/";
    const ADD_URL_LINK_TERKAIT = URL_APP + "api/v1/web_publish_link_terkait/";

    // import { Hooper, Slide } from 'hooper';

	export default {

        

		data() {
            return {
                id: "",
                list_slider: [],
                list_berita: [],
                list_foto : [],
                list_link_terkait : [],
                unit_kerja : UNIT_KERJA,

                tentang_dpmptsp: "",
                sambutan_isi: "",
                sambutan_nama: "",
                sambutan_foto: "",

                file_path: FILE_LOAD,
                galeryku : {
                    id : '',
                    judul : '',
                    deskripsi : '',
                    file : '',
                    unit_kerja : '',
                    status : '',
                    createBy : '',
                    createAt : '',
                },
			}
		},


		methods: {

            getSlider: function() {
                fetch(URL_SLIDER, {
                    method: "get",
                    headers: {
                        "content-type": "application/json",
                    },
                })
                    .then(res => res.json())
                    .then(res_data => { 
                        this.list_slider = res_data
                        // console.log(res_data);
                });
            },

            getListBerita: function() {
                this.cek_load_data_list = true;
                fetch(ADD_URL_BERITA+"homeOPD", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                    },
                        body: JSON.stringify({
                        unit_kerja: this.unit_kerja,
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_berita = res_data;
                        // this.cek_load_data = false;
                        // console.log(res_data);
                });
            },

			getTentang: function() {
            fetch(TENTANG_URL + "view", {
                method: "get",
                headers: {
                    "content-type": "application/json",
                },
            })
                .then(res => res.json())
                .then(res_data => { 
                    var profil = res_data[0];
                    this.tentang_dpmptsp = profil.uraian;
                    // console.log(res_data);
                });
            },

            getSambutan: function() {
            fetch(SAMBUTAN_URL + "view", {
                method: "get",
                headers: {
                    "content-type": "application/json",
                },
            })
                .then(res => res.json())
                .then(res_data => { 
                    var profil = res_data[0];
                    this.sambutan_isi = profil.isi;
                    this.sambutan_nama = profil.nama;
                    this.sambutan_foto = profil.foto;

                    // console.log(res_data);
                });
            },
        
            getFoto: function() {
                fetch(ADD_URL_FOTO, {
                    method: "get",
                    headers: {
                        "content-type": "application/json",
                    },
                })
                    .then(res => res.json())
                    .then(res_data => { 
                        this.list_foto = res_data
                        // console.log(res_data);
                });
            },
        
            getLinkTerkait: function() {
                fetch(ADD_URL_LINK_TERKAIT, {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                    },
                        body: JSON.stringify({
                        unit_kerja: this.unit_kerja,
                        
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_link_terkait = res_data;
                        // this.cek_load_data = false;
                        // console.log(res_data);
                });
            },

            selectDataFoto: function(data) {
                this.galeryku = {
                    id : data.id,
                    judul : data.judul,
                    deskripsi : data.deskripsi,
                    file : data.file,
                    unit_kerja : data.unit_kerja,
                    status : data.status,
                    createBy : data.createBy,
                    createAt : data.createAt,
                };
                // console.log(data);
            },

            cek_active : function(data){
                if (data == 0) {
                    return 'active'
                }
            },

            push_berita : function(id){
                this.$router.push('/isiBerita?id='+id);
            },

            buka_modal: function(modalku) {
                var modal = document.getElementById(modalku);
                modal.style.display = "block";
            },

            tutup_modal: function(modalku) {
                var modal = document.getElementById(modalku);
                modal.style.display = "none";
            },

            open_modal: function(){
                 $(window).load(function(){        
                    $('#myModal').modal('show');
                }); 
            },

            convert_tgl: function(dateString) {
                var date = new Date(dateString);
                var getBulan = date.getMonth() + 1; var bulan = '';
                if (getBulan == '1') {bulan = 'Jan'} 
                else if(getBulan == '2') {bulan = 'Feb'}
                else if(getBulan == '3') {bulan = 'Mar'}
                else if(getBulan == '4') {bulan = 'Apr'}
                else if(getBulan == '5') {bulan = 'Mei'}
                else if(getBulan == '6') {bulan = 'Jun'}
                else if(getBulan == '7') {bulan = 'Jul'}
                else if(getBulan == '8') {bulan = 'Agt'}
                else if(getBulan == '9') {bulan = 'Sep'}
                else if(getBulan == '10') {bulan = 'Okt'}
                else if(getBulan == '11') {bulan = 'Nov'}
                else if(getBulan == '12') {bulan = 'Des'}



                return date.getDate() + " " + bulan + " " + date.getFullYear();
                // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
            },

        },

		mounted() {
            this.getSlider();
            this.getTentang();
            this.getSambutan();
            this.getListBerita();
            this.getFoto();
            this.getLinkTerkait();
            var modal = document.getElementById('myModal');
            modal.style.display = "block";
		},

	}
</script>